import { ActivatedRoute, Router } from '@angular/router';
import { TermFacade } from 'src/app/shared/facades/term-facade';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationFacade } from 'src/app/views/authentication/facade/authentication.facade';
import { User } from 'src/app/views/authentication/models/user';
import { FileUploaderService } from '../../services/file-uploader.service';
import { FileParams } from 'src/app/views/dashboard/models/upload-file-params';
import { Component, Input, ViewChild } from '@angular/core';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';
import { AdminFileUploadComponent } from '../../components/admin-file-upload/admin-file-upload.component';
import { DataIngestionFacade } from 'src/app/views/dashboard/facade/data-ingestion.facade';
import { catchError, of } from 'rxjs';
import { FileUploadPayload } from '../../models/file-upload-payload';
import { DataIngestionService } from 'src/app/views/dashboard/services/data-ingestion.service';

@Component({
  selector: 'app-admin-file-upload-container',
  templateUrl: './admin-file-upload.container.html',
})
export class AdminFileUploadContainerComponent {
  @ViewChild(AdminFileUploadComponent) childComponent: AdminFileUploadComponent;
  @Input() isOpen: boolean;
  constructor(
    public notificationService: NotificationService,
    private authenticationFacade: AuthenticationFacade,
    private termFacade: TermFacade,
    public router: Router,
    private fileUploaderService: FileUploaderService,
    private route: ActivatedRoute,
    private dataIngestionFacade: DataIngestionFacade,
    private dataIngestionService: DataIngestionService
  ) {}

  private user$ = this.authenticationFacade.user$;
  public user: User;
  public allTermData$ = this.termFacade.allTermData$;
  public fileUploadStatus: boolean;
  public preSelectedTermId: number;
  public fileUploadMap = {};
  public uploadCheckArr: boolean[] = [];

  ngOnInit(): void {
    this.user$.subscribe((d) => {
      this.user = d;
    });

    const termId = +this.route.snapshot.queryParamMap.get('term_id');
    if (termId) {
      this.preSelectedTermId = termId;
    }
  }

  public async onUpload(payload: FileParams) {
    const termId = payload.term['id'];
    const dataIngestions: DataIngestion[] = [];
    // const formData = new FormData();
    const fileNameArr: string[] = [];
    for (const droppedFile of payload.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const file = await new Promise<File>((resolve, reject) => {
          fileEntry.file(
            (file: File) => {
              resolve(file);
            },
            (error: any) => {
              reject(error);
            }
          );
        });
        // CREATE A FILE MAP WITH FILE NAME AS KEY
        const file_name = file.name.split('.')[0];
        const file_type = file.name.split('.')[1];
        this.fileUploadMap[file_name] = file;
        const data_type = 1; // TODO: this needs to be dynamic
        const organization = this.user?.organization;
        const user = this.user?.id;
        const ingestion: DataIngestion = {
          file_name,
          file_type,
          data_type,
          organization,
          term: termId,
          user,
        };
        fileNameArr.push(file.name);
        dataIngestions.push(ingestion);
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
    const fileUploadPayload: FileUploadPayload = {
      fileList: fileNameArr,
      termId: termId,
    };

    if (payload.received_date_map) {
      // formData.append('received_date_map', JSON.stringify(payload.received_date_map));
      // formData.append('fiscal_year', payload.fiscal_year);
      fileUploadPayload.received_date_map = JSON.stringify(payload.received_date_map);
      fileUploadPayload.fiscal_year = payload.fiscal_year;
    }
    this.fileUploaderService
      .receiveSignedUploadURL(fileUploadPayload)
      .pipe(
        catchError((error) => {
          this.notificationService.openErrorSnackBar(
            error.error.message || 'An error occurred generating and receiving upload URL(s)'
          );
          return of(null); // Return a new observable to continue the observable chain
        })
      )
      .subscribe((d) => {
        if (d && d['response'] === 'success' && d['data']) {
          const signedUploadUrls = d['data'];
          fileNameArr.forEach((file: string) => {
            const fileName = file.split('.')[0];
            const ingestion_id = signedUploadUrls[fileName]['ingestion_id'];
            const patchPayload = { id: ingestion_id, data: {file_uploaded: true} };
            const url = signedUploadUrls[fileName]['signed_url'];
            const uploadFile = this.fileUploadMap[fileName];

            this.fileUploaderService
              .uploadFileToGCP(url, uploadFile)
              .pipe(
                catchError((error) => {
                  this.notificationService.openErrorSnackBar(
                    error.error.message || `An error occurred uploading ${fileName} file`
                  );
                  return of(null);
                })
              )
              .subscribe((d) => {
                this.dataIngestionService.patchDataIngestion(patchPayload).subscribe((d) => {
                  console.log(d, d.file_uploaded);
                  if (d.file_uploaded) {
                    this.uploadCheckArr.push(true);
                  }
                  if (this.uploadCheckArr.length === fileNameArr.length) {
                    const req = this.notificationService.openSuccessSnackBar('File has been uploaded to the bucket');
                    this.fileUploadStatus = true;
                    this.dataIngestionFacade.getAllIInstances(payload.term['data_type']);
                    setTimeout(() => {
                      this.closeMenu();
                    }, 2000);
                  }
                });
              });
          });
        }
      });
  }

  closeMenu() {
    this.childComponent.closeMenu();
  }
}
